<template>
  <div class="mentions">
    <article class="p-alt adapt text-justify bottom-20">
      <p>
       {{ $t('legal_notice.section1') }}
        &nbsp;<strong>sgg.gouv.bj</strong>&nbsp; {{ $t('legal_notice.sect1') }}
      </p>
      <p>
        {{ $t('legal_notice.section2') }}
      </p>
      <h2>
        {{ $t('legal_notice.title1') }}&nbsp;
      </h2>
      <p>
        {{ $t('legal_notice.section3') }}
      </p>
      <h2>{{ $t('legal_notice.title2') }}</h2>
      <p>
        {{ $t('legal_notice.section4') }}&nbsp;
      </p>
      <h2>{{ $t('legal_notice.title3') }}</h2>
      <p>
        {{ $t('legal_notice.section5') }}
      </p>
      <h2><strong>{{ $t('legal_notice.title4') }}</strong></h2>
      <p>
        {{ $t('legal_notice.section6') }}
      </p>
      <h2>
        <strong>{{ $t('legal_notice.title5') }}&nbsp;</strong>
      </h2>
      <p>
        {{ $t('legal_notice.section7') }}
      </p>
      <p>
        {{ $t('legal_notice.section8') }}&nbsp;
      </p>
      <h2><strong>{{ $t('legal_notice.title6') }}</strong></h2>
      <p>
        {{ $t('legal_notice.section9') }}
      </p>
      <h2><strong>{{ $t('legal_notice.title7') }}</strong></h2>
      <p>
        {{ $t('legal_notice.section10') }}&nbsp;
      </p>
      <h2>{{ $t('legal_notice.title8') }}</h2>
      <p>
        {{ $t('legal_notice.section11') }}nbsp;
      </p>
      <p>
        {{ $t('legal_notice.section12') }}
      </p>
      <h2>{{ $t('legal_notice.title9') }}</h2>
      <p>
        {{ $t('legal_notice.section13') }}
      </p>
      <h2>{{ $t('legal_notice.title10') }}</h2>
      <p>
        {{ $t('legal_notice.section14') }}
      </p>
      <h2>{{ $t('legal_notice.title11') }}</h2>
      <p>
        {{ $t('legal_notice.section15') }}&nbsp;
      </p>
      <p>
        {{ $t('legal_notice.section16') }}
      </p>
      <ul>
        <li>
          <strong>{{ $t('legal_notice.sect2') }}</strong>&nbsp;: {{ $t('legal_notice.section19') }}
        </li>
        <li>
          <strong>{{ $t('legal_notice.sect3') }}&nbsp;</strong>: {{ $t('legal_notice.section20') }}
        </li>
        <li>
          <strong>{{ $t('legal_notice.sect4') }}&nbsp;</strong>: {{ $t('legal_notice.section21') }}
        </li>
        <li>
          <strong>{{ $t('legal_notice.sect5') }}&nbsp;</strong>:  {{ $t('legal_notice.section22') }}
        </li>
        <li>
          <strong> {{ $t('legal_notice.sect6') }}</strong>&nbsp;:  {{ $t('legal_notice.section23') }}
        </li>
      </ul>
      <p> {{ $t('legal_notice.section17') }}
      </p>
      <h2> {{ $t('legal_notice.title12') }}</h2>
      <p>
        {{ $t('legal_notice.section18') }}
      </p>
    </article>
  </div>
</template>
<script>
import { BCard, BCardBody, BCardText } from 'bootstrap-vue';
export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
  },
};
</script>

<style scoped>
.mentions {
  background-color: #fff;
  padding: 3em;
  margin-bottom: 2em;
}
</style>
